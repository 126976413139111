/**
 * Decodes an HTML string into a DOM element.
 * @param {string} html_string - The HTML string to decode.
 * @returns {Element} - The decoded DOM element.
 */
const decodeHtml = function(html_string) {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = html_string;
  return wrapper.firstChild;
};


/**
 * Gives access to a decoder function (decodeHtml)
 * @param {object} controller - The URL of the script to load.
 */
const useDecoder = controller => {
  Object.assign(controller, {
    decodeHtml,
  });
};

export { decodeHtml, useDecoder };

export default useDecoder;
