import ApplicationController from "../application_controller.js";
import { useThrottle } from "stimulus-use";

export default class extends ApplicationController  {
  static debounces = ["manageScroll"];

  connect() {
    this.log_function("connect");

    useThrottle(this);

    this.show();
    this.boundManageScroll = this.manageScroll.bind(this);
    window.addEventListener("scroll", this.boundManageScroll);
  }

  hide() {
    this.log_function("hide");

    this.element.style.setProperty("display", "none", "important");
    document.body.dataset.espaBannerOpen = false;
    document.getElementById("floating_contact_button").classList.remove("pb-5", "pb-sm-5", "pb-md-5", "pb-lg-5", "pb-xl-5", "pb-xxl-5");
  }

  show() {
    this.log_function("show");

    this.element.style.removeProperty("display");
    document.body.dataset.espaBannerOpen = true;
    document.getElementById("floating_contact_button").classList.add("pb-5", "pb-sm-5", "pb-md-5", "pb-lg-5", "pb-xl-5", "pb-xxl-5");
  }

  manageScroll() {
    this.log_function("manageScroll");

    const scroll = window.scrollY;
    if(scroll > 25) {
      this.hide();
    } else {
      this.show();
    }
  }

  disconnect(){
    this.log_function("disconnect");

    delete document.body.dataset.espaBannerOpen;
    window.removeEventListener("scroll", this.boundManageScroll);
  }
}
