import ApplicationController from "../application_controller.js";
import lightGallery from "lightgallery";

export default class extends ApplicationController  {
  static classes = [ "container" ];
  static values = {
    //* We paid for the licence and it gets passed in via rails credentials.
    licenseKey: { type: String, default: "0000-0000-000-0000" },
  };

  connect() {
    this.log_function("connect");

    this.lgInstance = lightGallery(this.element, {
      licenseKey: this.licenseKeyValue,
      addClass: this.containerClass,
      download: false,
      counter: false,
      enableDrag: false,
      enableSwipe: false,
      // controls: false,
      getCaptionFromTitleOrAlt: false,
      mobileSettings: {
        showCloseIcon: true,
        download: false,
        counter: false,
      },
    });
  }

  disconnect(){
    this.log_function("disconnect");

    this.lgInstance.destroy();
  }
}
