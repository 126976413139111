import ApplicationController from "../application_controller.js";
import { useMutation } from "stimulus-use";

export default class extends ApplicationController  {
  static targets = [ "container" ];

  connect() {
    this.log_function("connect");

    useMutation(this, { childList: true, subtree: true });
  }


  //* Any time widgets get changed (ie by external scripts)
  //* Our scripts run and repair any damage to accessibility
  mutate(entries) {
    this.log_function("mutated", entries);

    //* triggered when the observed element is changed
    for (const mutation of entries) {
      if (mutation.type === "childList") {
        this.log_function("mutate", "A child node has been added or removed.");
        this.fix_empty_link();
        this.fix_image_alt();
        this.fix_img_display();
      } else if (mutation.target === this.element) {
        this.log_function("mutate", "The root element of this controller was modified.");
      } else if (mutation.type === "attributes") {
        this.log_function("mutate", `The ${mutation.attributeName} attribute was modified.`);
      }
    }
  }


  //* To pass accessibility tests all links need content
  //* fix_empty_link() is to add children to an empty link
  //* Example <a href="https://..."><!--- NOTHING HERE --></a>
  //* Becomes <a href="https://..."><span style="display:none;">FIXED</span></a>
  fix_empty_link() {
    this.log_function("fix_empty_link");

    if (this.has_links) {
      for (let link_index = 0; link_index < this.links.length; link_index++){
        let link = this.links[link_index];
        if (!link.innerHTML || link.innerHTML === "") {
          link.innerHTML = "<span style=\"display:none;\">FIXED</span>";
        }
      }
    }
  }

  //* To pass accessibility tests all images need an alt attribute value
  //* Additionally you cant have two images nex to each other with the same alt attribute
  //* fix_image_alt() is to add unique alt tags to images and to make alt tags unique
  //* Example <img src="https://..." /> becomes <img src="https://..." alt="-FIXED-0" />
  //* Example <img src="https://..." alt="TRIPADVISOR" /> becomes <img src="https://..." alt="TRIPADVISOR-FIXED-0" />
  fix_image_alt(){
    this.log_function("fix_image_alt");

    if (this.has_images) {
      for (let image_index = 0; image_index < this.images.length; image_index++) {
        let image = this.images[image_index];
        if (!image.alt.endsWith(`-FIXED-${image_index}`)) {
          image.alt += `-FIXED-${image_index}`;
        }
      }
    }
  }


  fix_img_display(){
    this.log_function("fix_img_display");

    if (this.has_images) {
      for (let image_index = 0; image_index < this.images.length; image_index++) {
        let image = this.images[image_index];
          image.style.display = "inline-block";
      }
    }
  }

  get has_images(){
    return this.images.length > 0;
  }

  get images(){
    return this.containerTarget.getElementsByTagName("img");
  }

  get has_links(){
    return this.links.length > 0;
  }

  get links(){
    return this.containerTarget.getElementsByTagName("a");
  }

  disconnect() {
    this.log_function("disconnect");
  }
}
