import ApplicationController from "../../application_controller.js";
import { useDebounce } from "stimulus-use";
import { useViewportSize } from "mixins";

export default class extends ApplicationController  {
  static debounces = ["resize"];
  static values = {
    viewport: String,
  };

  connect() {
    this.log_function("connect");

    useDebounce(this);
    useViewportSize(this);

    this.set_viewport_size();
  }

  open_slide(event){
    this.log_function("open_slide");

    if(this.viewportValue == "xs") return false;
    if(this.viewportValue == "sm") return false;

    this.dispatch("open_slide", { detail: { slide_index: event.currentTarget.dataset.slideIndex }, prefix: false });
  }

  set_viewport_size(){
    this.log_function("set_viewport_size");

    this.viewportValue = this.viewport_label();
  }

  resize(){
    this.log_function("resize");

    this.set_viewport_size();
  }
}
