export default class MapboxGLButtonControl {
  constructor({
    className = "",
    style = "",
    title = "",
    toggleOnClassName = "",
    toggleOnLabel = "",
    toggleOffClassName = "",
    toggleOffLabel = "",
    eventHandler = eventHandler,
    toggleState = true,
  }) {
    this._className = className || toggleOnClassName;
    this._style = style;
    this._title = title;
    this._eventHandler = eventHandler;
    this._toggleOnClassName =  toggleOnClassName || className;
    this._toggleOffClassName =  toggleOffClassName || className;
    this._toggleOnLabel =  toggleOnLabel;
    this._toggleOffLabel =  toggleOffLabel;
    this._toggleState = toggleState;
    this._toggleClass =  toggleOnClassName || className;
  }

  onAdd(map) {
    this._btn = document.createElement("button");
    this._btn.className = `mapboxgl-ctrl-icon ${this._className}`;
    this._btn.type = "button";
    this._btn.title = this._title;
    this._btn.onclick = this._eventHandler;
    this._btn.style = this._style;

    if (this._toggleOnLabel && this._toggleOffLabel) {
      this._btn.innerHTML = this._toggleOnLabel;
    }

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(this._btn);

    if((this._toggleOnClassName && this._toggleOffClassName) || (this._toggleOnLabel && this._toggleOffLabel)) {
      this._btn.onclick = () => {
        this._toggleState = !this._toggleState;

        this._btn.innerHTML = this._toggleState ? this._toggleOnLabel : this._toggleOffLabel;
        this._toggleClass = this._toggleState ? this._toggleOnClassName : this._toggleOffClassName;
        this._btn.className = `mapboxgl-ctrl-icon ${this._toggleClass}`;
        this._eventHandler();
      };

    }

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
