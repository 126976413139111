import ApplicationController from "../application_controller.js";

export default class extends ApplicationController  {
  static classes = ["animateIn", "animateOut", "animateSpeed"];
  static values = {
    delay: { type: Number, default: 1000 },
    enabled: { type: Boolean, default: true },
  };

  static targets = ["container", "item"];

  connect() {
    this.log_function("connect");

    if (this.enabledValue) {
      this.index = 0;

      this.containerToggleClass(this.animateInClass);

      if (this.animateSpeedClass) {
        this.containerTargets.forEach(() => {
          this.containerToggleClass(this.animateSpeedClass);
        });
      }

      this.interval = setInterval(() => {
        if (!this.containerHasClass(this.animateInClass)) {
          this.cycleContent();
        }

        this.containerToggleClass(this.animateInClass);
        this.containerToggleClass(this.animateOutClass);
      }, this.delayValue);
    }
  }

  containerToggleClass(className) {
    this.log_function("containerToggleClass", className);

    return this.containerTarget.classList.toggle(className);
  }

  containerHasClass(className) {
    this.log_function("containerHasClass", className);

    return this.containerTarget.classList.contains(className);
  }

  cycleContent() {
    this.log_function("cycleContent");

    //* Update the index first because the initial item is already rendered
    this.updateIndex();
    this.containerTarget.innerHTML = this.itemTargets[this.index].innerHTML;
  }

  updateIndex() {
    this.log_function("updateIndex");

    this.index++;

    //* Reset index to 0 if we've reached the end of the array
    if (this.index >= this.itemTargets.length) {
      this.index = 0;
    }
  }

  disconnect() {
    this.log_function("disconnect");

    if (this.enabledValue) {
      clearInterval(this.interval);
    }
  }
}
