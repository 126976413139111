/**
 * Calculates the viewport width of the browser window.
 * @function
 * @returns {number} The viewport width in pixels.
 */
const viewport_width = function(){
  let width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth,
    0,
  );
  return width;
};


/**
 * Determines the viewport label based on the given width.
 * @function
 * @param {number} width - The viewport width in pixels.
 * @returns {string} The corresponding viewport label (one of "xxl", "xl", "lg", "md", "sm", "xs").
 */
const viewport_label_lookup = function(width){
  let viewportLabel = "xxl";
  if (width < 1400) viewportLabel = "xl";
  if (width < 1200) viewportLabel = "lg";
  if (width < 992) viewportLabel = "md";
  if (width < 768) viewportLabel = "sm";
  if (width < 576) viewportLabel = "xs";

  return viewportLabel;
};


/**
 * Determines the current viewport label.
 * @function
 * @returns {string} The corresponding viewport label (one of "xxl", "xl", "lg", "md", "sm", "xs").
 */
const viewport_label = function(){
  const width = viewport_width();
  const viewportLabel = viewport_label_lookup(width);
  return viewportLabel;
};


/**
 * Assigns viewport size related methods to a controller object.
 * @function
 * @param {object} controller - The controller object to assign the methods to.
 * @returns {undefined}
 */
const useViewportSize = controller => {
  Object.assign(controller, {
    viewport_width,
    viewport_label_lookup,
    viewport_label,
  });
};

export { viewport_width, viewport_label_lookup, viewport_label, useViewportSize };
export default useViewportSize;
