import ApplicationController from "../application_controller.js";
import Popover from "bootstrap/js/dist/popover";

export default class extends ApplicationController  {
  static targets = [ "button", "source" ];
  static values = {
    success: { type: String, default: "COPIED" },
    error: { type: String, default: "ERROR" },
    feedbackDuration: { type: Number, default: 2000 },
    feedbackType: { type: String, default: "text" }, //* text OR popover OR alert
  };

  connect() {
    this.log_function("connect");

    if(!this.hasButtonTarget) return;

    this.originalText = this.buttonTarget.innerHTML;

    if (this.feedbackTypeValue === "popover") {
      this.popover = new Popover(this.buttonTarget, {
        container: "body",
        content: " ",
        placement: "top",
        offset: [0, 0],
        trigger: "manual",
      });
    }

  }

  copy(event) {
    this.log_function("copy", event);

    event.preventDefault();

    try {
      navigator.clipboard.writeText(this.sourceTarget.value)
      .then(() => {
        this.send_feedback(this.successValue);
        return this.successValue;
      })
      .catch((error) => {
        this.log_error_function("copy", error);
        this.send_feedback(this.errorValue);
      });
    } catch(error) {
      if(location.protocol !== "https:"){
        this.log_error_function("copy", "Clipboard API only available on HTTPS!");
      }
      this.log_error_function("copy", error);
      this.send_feedback(this.errorValue);
    }
  }

  send_feedback(feedback) {
    this.log_function("send_feedback", feedback);

    if(!this.hasButtonTarget) return;
    switch (this.feedbackTypeValue) {
      case "text":
        this.trigger_text_feedback(feedback);
        break;
      case "popover":
        this.trigger_popover(feedback);
        break;
      case "alert":
        this.trigger_alert.alert(feedback);
        break;
      default:
        this.log_error_function("send_feedback", `Feedback type ${this.feedbackTypeValue} is incompatible`);
        break;
    }
  }

  trigger_text_feedback(content) {
    this.log_function("trigger_text_feedback", content);

    if (!this.text_shown) {
      this.text_shown = true;
      this.buttonTarget.innerHTML = content;

    this.popover_timeout = setTimeout(() => {
        this.buttonTarget.innerHTML = this.originalText;
        this.text_shown = false;
      }, this.feedbackDurationValue);
    }
  }

  trigger_popover(content) {
    this.log_function("trigger_popover", content);

    if (!this.popover_shown) {
      this.popover_shown = true;
      this.popover.setContent({ ".popover-body": content });
      this.popover.show();

      this.popover_timeout = setTimeout(() => {
        this.popover.hide();
        this.popover_shown = false;
      }, this.feedbackDurationValue);
    }
  }

  trigger_alert(content){
    this.log_function("trigger_alert", content);

    window.alert(content);
  }

  disconnect(){
    this.log_function("disconnect");

    if(this.original_value_timeout) clearTimeout(this.original_value_timeout);
    if(this.popover_timeout) clearTimeout(this.popover_timeout);
  }

}
