//* https://tobiasahlin.com/moving-letters
import ApplicationController from "../application_controller.js";
import anime from "animejs";

export default class extends ApplicationController  {
  static values = {
    effect:       { type: Number,  default: 0     },
    delay:        { type: Number,  default: 0     },
    loop:         { type: Boolean, default: false },
    styled:       { type: Boolean, default: false },
    loopDelay:    { type: Number,  default: 0     },
  };

  connect() {
    this.log_function("connect");

    const force_motion = true;

    if(this.prefers_reduced_motion && !force_motion){
      this.dispatch("ahoy_trackEvent", { detail: { type: "accessibility", action: "prefers reduced motion" }, prefix: false });
    } else{
      let effect = false;
      this.timeout = false;
      switch (this.effectValue) {
        case 0:  effect = false;                     break;
        case 1:  effect = this.effect_1.bind(this);  break;
        case 2:  effect = this.effect_2.bind(this);  break;
        case 3:  effect = this.effect_3.bind(this);  break;
        case 4:  effect = this.effect_4.bind(this);  break;
        case 5:  effect = this.effect_5.bind(this);  break;
        case 6:  effect = this.effect_6.bind(this);  break;
        case 7:  effect = this.effect_7.bind(this);  break;
        case 8:  effect = this.effect_8.bind(this);  break;
        case 9:  effect = this.effect_9.bind(this);  break;
        case 10: effect = this.effect_10.bind(this); break;
        case 11: effect = this.effect_11.bind(this); break;
        case 12: effect = this.effect_12.bind(this); break;
        case 13: effect = this.effect_13.bind(this); break;
        case 14: effect = this.effect_14.bind(this); break;
        case 15: effect = this.effect_15.bind(this); break;
        case 16: effect = this.effect_16.bind(this); break;
        default: this.log_error_function("connect", "Please select valid animation"); break;
      }

      if (effect) {
        this.hide();
        this.timeout = setTimeout(effect, this.delayValue);
      }

    }
  }

  hide(){
    this.log_function("hide");

    this.initial_display_value = this.element.style.display;
    this.element.style.opacity = 0;
  }

  show(){
    this.log_function("show");

    this.element.style.opacity = 1;
  }

  disconnect(){
    this.log_function("disconnect");

    if(this.timeout){
      clearTimeout(this.timeout);
    }
  }


  //* Thursday
  effect_1(){
    this.log_function("effect_1");
    this.letters = this.element.getElementsByClassName("letter");
    this.lines = this.element.getElementsByClassName("line");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      scale: [0.3, 1],
      opacity: [0, 1],
      translateZ: 0,
      easing: "easeOutExpo",
      duration: 600,
      delay: (el, i) => 70 * ( i + 1),
    })
    .add({
      targets: this.lines,
      scaleX: [0, 1],
      opacity: [0.5, 1],
      easing: "easeOutExpo",
      duration: 700,
      offset: "-=875",
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Sunny Mornings
  effect_2(){
    this.log_function("effect_2");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      scale: [4, 1],
      opacity: [0, 1],
      translateZ: 0,
      easing: "easeOutExpo",
      duration: 950,
      delay: (el, i) => 70 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Great Thinkers
  effect_3(){
    this.log_function("effect_3");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 500,
      delay: (el, i) => 20 * (i + 1),
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Ready Set Go
  effect_4(){
    this.log_function("effect_4");
    this.words = this.element.getElementsByClassName("word");

    this.timeline = anime.timeline({ loop: this.loopValue });
    Array.from(this.words).forEach((word, index) => {
      const delay = 350 * index;

      this.timeline
      .add({
        targets: word,
        opacity: [0, 1],
        scale: [0, 1],
        duration: 800,
        easing: "easeInExpo",
        delay: delay,
      })
      .add({
        targets: word,
        opacity: [1, 0],
        scale: [1, 0],
        duration: 800,
        easing: "easeInExpo",
        delay: delay,
      });
    });

    this.timeline.add({
      targets: this.words,
      opacity: [0, 1],
      scale: [0, 1],
      duration: 600,
      easing: "easeInExpo",
      delay: 350 * this.words.length,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Signal & Noise
  effect_5(){
    this.log_function("effect_5");

    const amp_count = this.element.getElementsByClassName("ampersand").length;

    //* Check if the Animation is compatible
    if(amp_count === 1){
      this.letters_start = this.element.getElementsByClassName("word-first");
      this.letters_end = this.element.getElementsByClassName("word-last");
      this.ampersand = this.element.getElementsByClassName("ampersand");
      this.lines = this.element.getElementsByClassName("line");

      this.timeline = anime.timeline({ loop: this.loopValue })
      .add({
        targets: this.lines,
        opacity: [0.5, 1],
        scaleX: [0, 1],
        easing: "easeInOutExpo",
        duration: 700,
      })
      .add({
        targets: this.lines,
        duration: 600,
        easing: "easeOutExpo",
        translateY: (element, index) => (-0.625 + 0.625 * 2 * index) + "em",
      })
      .add({
        targets: this.ampersand,
        opacity: [0, 1],
        scaleY: [0.5, 1],
        easing: "easeOutExpo",
        duration: 600,
        offset: "-=600",
      })
      .add({
        targets: this.letters_start,
        opacity: [0, 1],
        translateX: ["0.5em", 0],
        easing: "easeOutExpo",
        duration: 600,
        offset: "-=300",
      })
      .add({
        targets: this.letters_end,
        opacity: [0, 1],
        translateX: ["-0.5em", 0],
        easing: "easeOutExpo",
        duration: 600,
        offset: "-=600",
        endDelay: this.loopDelayValue,
      });
    } else {
      this.log_function("effect_5", "effect_5 aka Signal & Noise needs to have at least one & in the text! you have", amp_count);
    }

    this.show();
  }


  //* Beautiful Questions
  effect_6(){
    this.log_function("effect_6");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      translateY: ["1.1em", 0],
      translateZ: 0,
      duration: 750,
      delay: (el, i) => 50 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Reality Is Broken
  effect_7(){
    this.log_function("effect_7");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      translateY: ["1.1em", 0],
      translateX: ["0.55em", 0],
      translateZ: 0,
      rotateZ: [180, 0],
      duration: 750,
      easing: "easeOutExpo",
      delay: (el, i) => 50 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Hi!
  effect_8(){
    this.log_function("effect_8");
    this.words = this.element.getElementsByClassName("word");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.words,
      scale: [0, 1],
      duration: 1200,
      offset: "-=550",
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Coffee Mornings
  effect_9(){
    this.log_function("effect_9");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      scale: [0, 1],
      duration: 1500,
      elasticity: 600,
      delay: (el, i) => 45 * (i + 1),
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Domino Dreams
  effect_10(){
    this.log_function("effect_10");
    this.letters = this.element.getElementsByClassName("letter");


    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      rotateY: [-90, 0],
      duration: 1300,
      delay: (el, i) => 45 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Hello Goodbye
  effect_11(){
    this.log_function("effect_11");
    this.letters = this.element.getElementsByClassName("letter");
    this.lines = this.element.getElementsByClassName("line");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.lines,
      scaleY: [0, 1],
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 800,
    })
    .add({
      targets: this.lines,
      translateX: [0, this.element.getBoundingClientRect().width + 10],
      easing: "easeOutExpo",
      duration: 1000,
      delay: 100,
    })
    .add({
      targets: this.letters,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 700,
      delay: (el, i) => 34 * ( i + 1),
    }, 900)
    .add({
      targets: this.lines,
      scaleY: [1, 0],
      opacity: [1, 0],
      duration: 1000,
      easing: "easeOutExpo",
      delay: 500,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* A New Production
  effect_12(){
    this.log_function("effect_12");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      translateX: [40, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (el, i) => 500 + 30 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Rising Strong
  effect_13(){
    this.log_function("effect_13");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Find Your Element
  effect_14(){
    this.log_function("effect_14");
    this.letters = this.element.getElementsByClassName("letter");
    this.lines = this.element.getElementsByClassName("line");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.lines,
      scaleX: [0, 1],
      opacity: [0.5, 1],
      easing: "easeInOutExpo",
      duration: 900,
    })
    .add({
      targets: this.letters,
      opacity: [0, 1],
      translateX: [40, 0],
      translateZ: 0,
      scaleX: [0.3, 1],
      easing: "easeOutExpo",
      duration: 800,
      offset: "-=600",
      delay: (el, i) => 150 + 25 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Out Now
  effect_15(){
    this.log_function("effect_15");
    this.words = this.element.getElementsByClassName("word");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.words,
      scale: [14, 1],
      opacity: [0, 1],
      easing: "easeOutCirc",
      duration: 800,
      delay: (el, i) => 800 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }


  //* Made With Love
  effect_16(){
    this.log_function("effect_16");
    this.letters = this.element.getElementsByClassName("letter");

    this.timeline = anime.timeline({ loop: this.loopValue })
    .add({
      targets: this.letters,
      translateY: [-100, 0],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 30 * i,
      endDelay: this.loopDelayValue,
    });

    this.show();
  }

  get prefers_reduced_motion(){
    this.log_function("get prefers_reduced_motion");

    let prefers_reduced_motion = false;

    try {
      if("matchMedia" in window){
        prefers_reduced_motion = window.matchMedia("(prefers-reduced-motion)").matches;
      }
    } catch (error) {
      this.log_error_function("get prefers_reduced_motion", error);
    }
    this.log_function("get prefers_reduced_motion", prefers_reduced_motion);
    return prefers_reduced_motion;
  }

}
