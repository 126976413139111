/* global Stimulus */
console.log("template0/home.js Loaded");

//* Bootstrap
import "bootstrap/js/dist/alert"; //* For the adult friendly
import "bootstrap/js/dist/collapse"; //* For the adult friendly

//* Third Party Libraries

//* Stimulus
import hero_gallery_bootstrap from "../../view_components/hero_gallery/bootstrap/index.js";
Stimulus.register("hero-gallery--bootstrap", hero_gallery_bootstrap);

import moving_letters from "../../view_components/moving_letters/index.js";
Stimulus.register("moving-letters", moving_letters);

import fit_text from "../../view_components/fit_text/index.js";
Stimulus.register("fit-text", fit_text);

import parallax_divider from "../../view_components/parallax_divider/index.js";
Stimulus.register("parallax-divider", parallax_divider);

import gallery from "../../view_components/gallery/index.js";
Stimulus.register("gallery", gallery);

import map from "../../view_components/map/index.js";
Stimulus.register("map", map);

import confetti from "../../view_components/confetti/index.js"; //* confetti required for Area51 easter egg on the map
Stimulus.register("confetti", confetti);

import show_more from "../../view_components/show_more/index.js";
Stimulus.register("show-more", show_more);

import copyable_input from "../../view_components/copyable_input/index.js";
Stimulus.register("copyable-input", copyable_input);

import image_preview from "../../view_components/image_preview/index.js";
Stimulus.register("image-preview", image_preview);

import content_animator from "../../view_components/content_animator/index.js";
Stimulus.register("content-animator", content_animator);

import visibility from "../../view_components/visibility/index.js";
Stimulus.register("visibility", visibility);

import espa_banner from "../../view_components/espa_banner/index.js";
Stimulus.register("espa-banner", espa_banner);

import widget from "../../view_components/widget/index.js";
Stimulus.register("widget", widget);
