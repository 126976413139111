//* https://github.com/peacechen/FitText-UMD
import ApplicationController from "../application_controller.js";
import fitText from "FitText-UMD";

export default class extends ApplicationController {
  static values = {
    /**
     * @type {number}
     * @default 1
     * @description The default setting works pretty well, but when it doesn't FitText has one setting you can adjust. If your text resizes poorly or is resizing all hurdy gurdy, you'll want to turn tweak up/down the compressor.It works a little like a guitar amp. (can be a float like 0.8).
     * @example
     * compressor: 0.8
     * compressor: 1
     */
    compressor: { type: Number, default: 1 },

    /**
     * @type {object}
     * @default {}
     * @description FitText now allows you to specify two optional pixel values: `minFontSize` and `maxFontSize`. Great for situations when you want to preserve hierarchy.
     * @example
     * config: { minFontSize: '20px', maxFontSize: '40px' }
     */
    config: { type: Object, default: {} },
  };

  connect() {
    this.log_function("connect");
    fitText(this.element, this.compressorValue, this.configValue);
  }

  disconnect(){
    this.log_function("disconnect");
  }
}
