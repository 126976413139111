/* global mapboxgl, i18n */

import ApplicationController from "../application_controller.js";
import { useDebounce } from "stimulus-use";
import { useI18n, useAssetLoader, useDecoder, useColorHelper } from "mixins";
import MapboxGLButtonControl from "../../mapbox";
import translations from "../../locales/view_components/map.json";

export default class extends ApplicationController  {
  static values = {
    page_name: { type: String, default: "" },
    mode: { type: String, default: "default" },
    mapbox_username: String,
    mapbox_style_id: String,
    mapbox_access_token: String,
    latitude: String,
    longitude: String,
    zoom: { type: Number, default: 15 },

    //* Options for Marker
    marker_name: { type: String, default: "pin-l" },
    marker_label: { type: String, default: "" },
    marker_color: { type: String, default: "#3FB1CE" },
    marker_content: { type: String, default: "" },
    marker_anchor: { type: String, default: "center" },
    marker_scale: { type: Number, default: 1 },
    marker_icon: { type: String, default: "" },
    marker_icon_color: { type: String, default: "auto" },

    //* Options for popup
    popup_content: { type: String, default: "" },
    popup_anchor: { type: String, default: "bottom" },
    popup_class_name: { type: String, default: "" },
    popup_close_button: { type: Boolean, default: true },
    popup_close_on_click: { type: Boolean, default: true },
    popup_close_on_move: { type: Boolean, default: false },
    popup_focus_after_open: { type: Boolean, default: false },
    popup_max_width: { type: String, default: "240px" },
    popup_offset: { type: Number, default: 0 },

    //* Options for POI button
    icon_poi_enable: { type: String, default: "" },
    icon_poi_disable: { type: String, default: "" },
  };

  static targets = [ "cta", "static_map", "interactive_map" ];

  static classes = [ "loading", "loaded" ];

  static debounces = [ "checkzoom", "checkFullscreen" ];

  connect() {
    this.log_function("connect");

    useDebounce(this);
    useColorHelper(this);
    useAssetLoader(this);
    useDecoder(this);
    useI18n({
      controller: this,
      translations: translations,
      scope: "view_components.map",
    });

    this.mapAPIloaded = false;
    this.map = false;

    this.mapEvents = [];

    switch (this.modeValue) {
      case "static":
        this.loadStaticMap();
        this.ctaTarget.style.display = "none";
        this.element.style.cursor = "default";
        break;
      case "interactive":
        this.element.addEventListener("lazybeforeunveil", this.loadInteractiveMap.bind(this), { once : true });
        break;
      default:
        this.loadStaticMap();
        this.element.addEventListener("click", this.loadInteractiveMap.bind(this), { once : true });
        break;
    }
  }

  get marker_color() {
    this.log_function("get marker_color");

    //* This switch checks where to get the color value from
    switch (true) { //* if you don't understand why it is set to true, read this https://seanbarry.dev/posts/switch-true-pattern
      case this.markerColorValue.startsWith("#"): //* If the string starts with a #, it must be a HEX color code.
        return this.markerColorValue; //* Get the color from  the provided string
      case this.markerColorValue.startsWith("--"): //* If the string starts with a --, it must be a css variable.
        return getComputedStyle(document.documentElement).getPropertyValue(this.markerColorValue).trim(); //* Get the color value from the CSS variable
      default: //* If the string does not start with -- or # send a default color
        return "#FF0000"; //* Send a default color #FF0000 (red)
    }
    //* If you don't understand this code just ask Anthony
    //* If you are Anthony and still don't understand this code past Anthony is laughing now!
  }

  get marker_icon_color() {
    this.log_function("get marker_icon_color");

    if (this.markerIconColorValue === "auto") {
      //! We don't have the logic that MapBox uses so it's a bit of trial and error
      //* We adjust the threshold value as we test,
      //* We want to the static map marker icon and the interactive map marker icons to match
      return this.hex_contrasted_color(this.marker_color, { threshold: 150 });
    }

    //* If this.markerIconColorValue is not se to auto just return the color that it was set to
    return this.markerIconColorValue;
  }

  loadStaticMap() {
    this.log_function("loadStaticMap");

    //* If needing to load a custom marker icon in static map, refer to this documentation: https://docs.mapbox.com/api/maps/static-images/#example-request-retrieve-a-static-map-with-a-custom-marker-overlay

    let map_width = this.static_mapTarget.clientWidth;
    let map_height = this.static_mapTarget.clientHeight;
    const map_ratio = map_width / map_height;
    const max_size = 1280; //* max size of the map provided by mapbox in pixels (width or height)

    //* Mapbox requires an integer that is why Math.floor is used
    if(map_width > max_size || map_height > max_size) {
      if (map_width > map_height) {
        map_width = max_size;
        map_height = Math.floor(map_width / map_ratio);
      } else if (map_height > map_width) {
        map_height = max_size;
        map_width = Math.floor(map_height / map_ratio);
      } else {
        map_width = max_size;
        map_height = max_size;
      }
    }

    const static_map_dimensions = `${map_width}x${map_height}`;
    const username = this.mapboxUsernameValue;
    const style_id = this.mapboxStyleIdValue;
    const access_token = this.mapboxAccessTokenValue;

    //* Marker shape and size. Options are pin-s and pin-l.
    const marker_name = this.markerNameValue;

    //* Optional. Marker symbol. Options are a lowercase alphanumeric label a through z, 0 through 99, or a valid Maki icon. If a letter is requested, it will be rendered in uppercase only.
    const marker_label = this.markerLabelValue ? `-${this.markerLabelValue}` : "";

    //* Optional. A 3-digit or 6-digit HEX color code.
    const marker_color_no_alpha = this.hex_no_alpha(this.marker_color).replace("#", "").toLowerCase();

    //* The location at which to center the marker. When using an asymmetric marker, make sure that the tip of the pin is at the center of the image.
    const marker_longitude = this.longitudeValue;
    const marker_latitude = this.latitudeValue;

    const markerUrlParam = `${marker_name}${marker_label}+${marker_color_no_alpha}(${marker_longitude},${marker_latitude})/`;

    const src = `https://api.mapbox.com/styles/v1/${username}/${style_id}/static/${markerUrlParam}${this.longitudeValue},${this.latitudeValue},${this.zoomValue},0/${static_map_dimensions}@2x?access_token=${access_token}&logo=true&attribution=true`;

    this.static_mapTarget.setAttribute("data-src", src);
    this.static_mapTarget.classList.add("lazyload");
  }

  loadInteractiveMap() {
    this.log_function("loadInteractiveMap");

    this.element.classList.add(this.loadingClass);

    //* "https://api.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.css"
    this.load_style("/obfuscated/mapbox-gl/css/mapbox-gl.css");

    //* "https://api.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.js"
    this.load_script("/obfuscated/mapbox-gl/js/mapbox-gl.js", this.generateMap.bind(this) );

    this.element.removeEventListener("click", this.loadInteractiveMap.bind(this));
    this.element.removeEventListener("lazybeforeunveil", this.loadInteractiveMap.bind(this));

  }

  generateMap() {
    this.log_function("generateMap");

    const markerIcon = this.markerIconValue;
    const markerColor = this.marker_color;
    const markerIconColor = this.marker_icon_color;
    const styleForMarkerCircle = markerIcon ? "display: none;" : "";
    this.interactive_mapTarget.style.display = "block";
    this.mapAPIloaded = true;
    this.foundArea51 = false;

    const pinHtml =
      `<div aria-label="Map marker">
        <div style="margin-bottom: 35px;">
          <svg display="block" height="41px" width="27px" viewBox="0 0 27 41">
            <defs>
              <radialGradient id="shadowGradient">
                <stop offset="10%" stop-opacity="0.4"></stop>
                <stop offset="100%" stop-opacity="0.05"></stop>
              </radialGradient>
            </defs>
            <ellipse cx="13.5" cy="34.8" rx="10.5" ry="5.25" fill="url(#shadowGradient)"></ellipse>
            <path fill="${markerColor}" d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"></path>
            <path opacity="0.25" d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"></path>
            <circle fill="white" cx="13.5" cy="13.5" r="5.5" style="${styleForMarkerCircle}"></circle>
          </svg>
        </div>
        <div style="position: absolute; top: 50%; left:50%;transform: translate(-50%, -50%); margin-top: -24px; fill: ${markerIconColor};">
          ${markerIcon}
        </div>
      </div>`;

    mapboxgl.accessToken = this.mapboxAccessTokenValue;
    this.map = new mapboxgl.Map({
      container: this.interactive_mapTarget,
      zoom: this.zoomValue,
      // maxZoom: 15,
      center: [this.longitudeValue, this.latitudeValue],
      style: `mapbox://styles/${this.mapboxUsernameValue}/${this.mapboxStyleIdValue}`,
      exitFullscreenOnEsc: true,
      locale: {
        "AttributionControl.MapFeedback": this.t("controls.attribution.feedback"),
        "AttributionControl.ToggleAttribution": this.t("controls.attribution.toggle"),
        "FullscreenControl.Enter": this.t("controls.fullscreen.enter"),
        "FullscreenControl.Exit": this.t("controls.fullscreen.exit"),
        "GeolocateControl.FindMyLocation": this.t("controls.geolocate.find_my_location"),
        "GeolocateControl.LocationNotAvailable": this.t("controls.geolocate.location_not_available"),
        "LogoControl.Title": this.t("controls.logo.title"),
        "Map.Title": this.t("title"),
        "NavigationControl.ResetBearing": this.t("controls.navigation.reset_bearing"),
        "NavigationControl.ZoomIn": this.t("controls.navigation.zoom_in"),
        "NavigationControl.ZoomOut": this.t("controls.navigation.zoom_out"),
        "ScrollZoomBlocker.CmdMessage": this.t("scroll_zoom_blocker.cmd_message"),
        "ScrollZoomBlocker.CtrlMessage": this.t("scroll_zoom_blocker.ctrl_message"),
        "TouchPanBlocker.Message": this.t("touch_pan_blocker.message"),
      },
    });

    // this.map._logoControl._container.style.scale = 0.5;
    const marker = new mapboxgl.Marker({
      element: this.decodeHtml(this.markerContentValue || pinHtml),
      color: this.marker_color,
      anchor: this.markerAnchorValue,
      scale: this.markerScaleValue,
    })
    .setLngLat([this.longitudeValue, this.latitudeValue])
    .addTo(this.map);

    if(this.popupContentValue) {
      const popup = new mapboxgl.Popup({
        anchor: this.popupAnchorValue,
        className: this.popupClassNameValue,
        closeButton: this.popupCloseButtonValue,
        closeOnClick: this.popupCloseOnClickValue,
        closeOnMove: this.popupCloseOnMoveValue,
        focusAfterOpen: this.popupFocusAfterOpenValue,
        maxWidth: this.popupMaxWidthValue,
        offset: this.popupOffsetValue,
      }).setHTML(this.popupContentValue);
      marker.setPopup(popup).togglePopup();
    }

    const navigationControlOptions = {
      showCompass: false,
      showZoom: true,
      visualizePitch: true,
    };

    const centerMap = () => {
      this.map.flyTo({
        center: [this.longitudeValue, this.latitudeValue],
        zoom: this.zoomValue,
        pitch: 0,
        bearing: 0,
      });

      const centeredMap = "clicked centered map";
      if(!this.checkMapEventUsed(centeredMap)){
        this.updateMapEvents(centeredMap);
        this.dispatch("ahoy_trackEvent", { detail: { type: "ux", location: "map", action: centeredMap, page: this.pageNameValue }, prefix: false });
      }
    };

    const togglePoiMarkers = () => {
      const visibility = this.map.getLayoutProperty("poi-layer", "visibility") || "visible";

      let poiVisibility = false;
      if (visibility === "visible") {
        this.map.setLayoutProperty("poi-layer", "visibility", "none");
        poiVisibility = "clicked hide POIs";
      } else {
        this.map.setLayoutProperty("poi-layer", "visibility", "visible");
        poiVisibility = "clicked show POIs";
      }
      if(poiVisibility && !this.checkMapEventUsed(poiVisibility)){
        this.updateMapEvents(poiVisibility);
        this.dispatch("ahoy_trackEvent", { detail: { type: "ux", location: "map", action: poiVisibility, page: this.pageNameValue }, prefix: false });
      }
    };

    const ctrlPois = new MapboxGLButtonControl({
      className: "mapboxgl-ctrl-pois",
      toggleOnLabel: `${this.iconPoiEnableValue} ${this.t("controls.poi.hide")}`,
      toggleOffLabel: `${this.iconPoiDisableValue}  ${this.t("controls.poi.show")}`,
      title: `${this.t("controls.poi.title")}`,
      eventHandler: togglePoiMarkers,
    });

    const ctrlCenter = new MapboxGLButtonControl({
      className: "mapboxgl-ctrl-home",
      title: `${this.t("controls.center.title")}`,
      eventHandler: centerMap,
    });

    //* If a map scale indicator is required, uncomment the code below
    // const scale = new mapboxgl.ScaleControl({
    //   maxWidth: 200,
    //   unit: "metric",
    // });
    // this.map.addControl(scale);
    this.map.addControl(new mapboxgl.NavigationControl(navigationControlOptions), "bottom-right");
    this.map.addControl(ctrlCenter, "bottom-right");
    this.map.addControl(ctrlPois, "top-left");
    this.map.addControl(new mapboxgl.FullscreenControl(), "top-right");

    this.map.on("load", () => {
      //* This request get ALL map markers (added to pois layer)
      this.map.addSource("pois", {
        "type": "geojson",
        //* Use a URL for the value for the `data` property.
        "data": i18n._locale === "en" ? "/markers.json" : `/${i18n._locale}/markers.json`,
      });

      //* This request gets maps markers associated with a SPECIFIC listing (added to custom_markers layer)
      this.map.addSource("custom_markers", {
        "type": "geojson",
        //* Use a URL for the value for the `data` property.
        "data": i18n._locale === "en" ? "/markers.json?types=custom" : `/${i18n._locale}/markers.json?types=custom`,
      });

      let layerProperties = {
        "type": "symbol",
        "paint": {
          "text-color": "#fff",
          "text-halo-color": "#000",
          "text-halo-width": 1,
          // "text-opacity": 0.8,
        },
        "layout": {
          "icon-image": ["get", "icon"],
          "icon-offset": [0, -15],
          "icon-size": 0.9,
          //* get the title name from the source's "title" property
          "text-field": ["get", "title"],
          "text-size": 18,
          "text-justify": "center",
          "text-optional": true,
          // "text-letter-spacing": 0.1,
          "text-font": [
            "Open Sans Semibold",
            "Arial Unicode MS Bold",
          ],
          "text-offset": [0, 0],
          "text-anchor": "top",
        },
      };

      //* There are 2 separate layers in order to keep custom markers from
      //* being hidden when the show/hide POIs toggle button is clicked.
      this.map.addLayer({ "id": "poi-layer", "source": "pois", ...layerProperties });
      this.map.addLayer({ "id": "custom-markers-layer", "source": "custom_markers", ...layerProperties });
    });

    this.element.classList.add(this.loadedClass);

    this.interactiveMapLoadedTimeout = setTimeout(() => {
      this.static_mapTarget.style.display = "none";
      this.element.classList.remove(this.loadingClass);
    }, 2000);

    this.updateLastZoom();

    this.map.on("zoom", (event) => this.checkzoom(event) );

    this.fullScreenChange = false;
    if ("onfullscreenchange" in window.document) {
      this.fullScreenChange = "fullscreenchange";
    } else if ("onmozfullscreenchange" in window.document) {
      this.fullScreenChange = "mozfullscreenchange";
    } else if ("onwebkitfullscreenchange" in window.document) {
      this.fullScreenChange = "webkitfullscreenchange";
    } else if ("onmsfullscreenchange" in window.document) {
      this.fullScreenChange = "MSFullscreenChange";
    }

    if (this.fullScreenChange) {
      this.boundCheckFullscreen = this.checkFullscreen.bind(this);
      window.document.addEventListener(this.fullScreenChange, this.boundCheckFullscreen);
    }

    //* Disables map rotation using right click + drag
    this.map.dragRotate.disable();

    //* Disables map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    //* Disables map tilting using two-finger gesture
    this.map.touchPitch.disable();

    //* Disables the "box zoom" interaction.
    this.map.boxZoom.disable();

    this.map.on("moveend", (event) => {
      const currentBoundingBox = new mapboxgl.LngLatBounds(this.map.getBounds().toArray());
      const area51Location = new mapboxgl.LngLat(-115.812251, 37.234729);

      if(!this.foundArea51 && currentBoundingBox.contains(area51Location)){
        this.dispatch("ahoy_trackEvent", { detail: { type: "ux", location: "map", action: "found Area51", page: this.pageNameValue }, prefix: false });
        this.foundArea51 = true;

        this.confettiElement = document.createElement("div"); //* Create a new div element
        this.confettiElement.classList.add("position-absolute", "top-50", "start-50", "translate-middle"); //* Add classes to the div element
        this.confettiElement.setAttribute("data-controller", "confetti"); //* Set the data-controller attribute
        this.element.appendChild(this.confettiElement); //* Append the div element to the body or another element in the document

        this.confettiElementRemoveTimeout = setTimeout(() => {
          this.confettiElement.remove();
        }, 5000);
      }
    });
  }

  updateCurrentZoom(){
    this.log_function("updateCurrentZoom");

    this.currentZoom = this.map.getZoom();
  }

  updateLastZoom(){
    this.log_function("updateLastZoom");

    this.lastZoom = this.map.getZoom();
  }

  checkzoom(event){
    this.log_function("checkzoom");

    this.updateCurrentZoom();

    let used_type = false;
    if (event.originalEvent) {
      switch (event.originalEvent.type) {
        case "click":
          used_type = "clicked on button";
          break;
        case "touchstart", "touchend":
          used_type = "pinched on touchscreen";
          break;
        case "wheel":
          used_type = "scrolled on mouse";
          break;
        case "keyup", "keydown":
          used_type = "typed on keyboard";
          break;
        default:
          used_type = "method undetected";
          break;
      }
    }

    let zoom = false;
    switch (true) {
      case this.currentZoom > this.lastZoom:
        zoom = "zoom in";
        break;
      case this.currentZoom < this.lastZoom:
        zoom = "zoom out";
        break;
      default:
        zoom = "zoom none";
        break;
    }

    if(zoom && used_type) {
      const zoomUsed = `${used_type} | ${zoom}`;
      if(!this.checkMapEventUsed(zoomUsed)){
        this.updateMapEvents(zoomUsed);
        this.dispatch("ahoy_trackEvent", { detail: { type: "ux", location: "map", action: zoomUsed, page: this.pageNameValue }, prefix: false });
      }
    }

    this.updateLastZoom();
  }

  checkFullscreen(){
    this.log_function("checkFullscreen");

    let fullscreen = false;
    if(document.fullscreenElement && document.fullscreenElement.classList.contains("mapboxgl-map")){
      this.mapIsFullscreen = true;
      fullscreen = "entered fullscreen";
    } else if(this.mapIsFullscreen) {
      fullscreen = "exited fullscreen";
      this.mapIsFullscreen = false;
    }

    if(fullscreen) {
      if(!this.checkMapEventUsed(fullscreen)){
        this.updateMapEvents(fullscreen);
        this.dispatch("ahoy_trackEvent", { detail: { type: "ux", location: "map", action: fullscreen, page: this.pageNameValue }, prefix: false });
      }
    }
  }


  disconnect(){
    this.log_function("disconnect");

    if (this.mapAPIloaded) {
      clearTimeout(this.interactiveMapLoadedTimeout);
      this.map.remove();
    }

    if(this.confettiElementRemoveTimeout){
      clearTimeout(this.confettiElementRemoveTimeout);
      this.confettiElement.remove();
    }

    if (this.fullScreenChange) {
      window.document.removeEventListener(this.fullScreenChange, this.boundCheckFullscreen);
    }
  }

  updateMapEvents(event){
    this.log_function("updateMapEvents", event);

    this.mapEvents.push(event);
  }

  checkMapEventUsed(event){
    this.log_function("checkMapEventUsed", event);

    return this.mapEvents.includes(event);
  }
}
