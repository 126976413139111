import ApplicationController from "../application_controller.js";
import { confetti } from "dom-confetti";


export default class extends ApplicationController  {
  static values = {
    config: { type: Object, default: {} },
    mode: { type: String, default: "auto" },
    delay: { type: Number, default: 0 },
    times: { type: Number, default: 1 },
  };

  //* You can run this controller on events like click like this:
  //* 1. Set mode to manual
  //* 2. Add this to the element (data-action="click->confetti#showConfetti")

  connect() {
    this.log_function("connect");

    this.times_confetti_launched = 0;
    this.BondShowConfetti = this.showConfetti.bind(this);

    if (this.modeValue === "auto") {
      this.setupConfetti();
    }
  }

  setupConfetti() {
    this.log_function("setupConfetti");

    if(this.timesValue > 1) {
      this.setupInterval();
    } else if(this.delayValue > 0){
      this.setupTimeout();
    } else {
      this.showConfetti();
    }
  }

  setupInterval() {
    this.log_function("setupInterval");

    this.interval = setInterval(()=> {
      this.BondShowConfetti();
      this.times_confetti_launched++;
      if(this.times_confetti_launched >= this.timesValue){
        clearInterval(this.interval);
      }
    }, this.delayValue);
  }

  setupTimeout(){
    this.log_function("setupTimeout");

    this.showConfettiTimeout = setTimeout(()=>{
      this.BondShowConfetti();
    }, this.delayValue);
  }

  showConfetti(){
    this.log_function("showConfetti");

    confetti(this.element, this.config);
  }

  disconnect(){
    this.log_function("disconnect");

    clearTimeout(this.showConfettiTimeout);
  }

  get config() {
    this.log_function("get config");

    return {
      angle: 90,
      spread: 360,
      startVelocity: 40,
      elementCount: 70,
      dragFriction: 0.12,
      duration: 3000,
      stagger: 3,
      width: "10px",
      height: "10px",
      perspective: "500px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
      ...this.configValue,
    };
  }
}
