{
  "el": {
    "view_components": {
      "map": {
        "controls": {
          "attribution": {
            "feedback": "Σχόλια χάρτη",
            "toggle": "Εναλλαγή αναφοράς"
          },
          "center": {
            "title": "Κεντράρισμα Χάρτη"
          },
          "fullscreen": {
            "enter": "Πλήρη Οθόνη",
            "exit": "Έξοδος Από Πλήρη Οθόνη"
          },
          "geolocate": {
            "find_my_location": "Βρείτε Την Τοποθεσία Μου",
            "location_not_available": "Τοποθεσία Μη Διαθέσιμη"
          },
          "logo": {
            "title": "Λογότυπο Mapbox"
          },
          "navigation": {
            "reset_bearing": "Επαναφορά Προς Βορρά",
            "zoom_in": "Μεγέθυνση",
            "zoom_out": "Σμίκρυνση"
          },
          "poi": {
            "hide": "Απόκρυψη Σημεία Ενδιαφέροντος",
            "show": "Εμφάνιση Σημεία Ενδιαφέροντος",
            "title": "Σημεία Ενδιαφέροντος"
          }
        },
        "cta": "Κάντε Κλικ Για Ενεργοποίηση Του Χάρτη",
        "pois": {
          "archaeology": "Αρχαιολογία",
          "atm": "ATM",
          "bank": "Τράπεζα",
          "beach": "Παραλία",
          "bus_station": "Στάση Λεωφορείου",
          "car_electrician": "Ηλεκτρολόγος Αυτοκινήτων",
          "car_mechanic": "Μηχανικός αυτοκινήτων",
          "car_wash": "Πλυντήριο αυτοκινήτων",
          "cardiologist": "Καρδιολόγος",
          "cultural": "κουλτούρα",
          "dentist": "Οδοντίατρος",
          "electric_car_charger": "Σημείο Φόρτισης EV",
          "ent": "Ωτορινολαρυγγολόγος (ΩΡΛ)",
          "ferryport": "Λιμάνι",
          "fire_station": "Πυροσβεστική",
          "gas_station": "Βενζινάδικο",
          "gastroenterologist": "Γαστρεντερολόγος",
          "general_doctor": "Γενικός Γιατρός",
          "gynaecologist": "Γυναικολόγος",
          "landmark": "Ορόσημο",
          "marina": "Μαρίνα",
          "microbiologist": "Μικροβιολόγος",
          "motorcycle": "Μοτοσυκλέτα",
          "museum": "Μουσείο",
          "nature": "Φύση",
          "orthopedic": "Ορθοπεδικός",
          "parking": "Παρκινγκ",
          "pediatrician": "Παιδίατρος",
          "pharmacy": "Φαρμακείο",
          "police_station": "Αστυνομικό Τμήμα",
          "private_medical_center": "Ιδιωτικό Ιατρικό Κέντρο",
          "products": "Προϊόντα",
          "public_hospital": "Δημοσιο Νοσοκομειο",
          "pulmonologist": "Πνευμονολόγος",
          "shopping": "Αγορά",
          "taxi_stop": "Στάση Ταξί",
          "veterinarian": "Κτηνίατρος",
          "water_sports": "Θαλάσσια σπορ"
        },
        "scroll_zoom_blocker": {
          "cmd_message": "Χρησιμοποιήστε ⌘ + κύλιση για μεγέθυνση του χάρτη",
          "ctrl_message": "Χρησιμοποιήστε ctrl + κύλιση για μεγέθυνση του χάρτη"
        },
        "title": "Χάρτης",
        "touch_pan_blocker": {
          "message": "Χρησιμοποιήστε δύο δάχτυλα για να μετακινήσετε τον χάρτη"
        }
      }
    }
  },
  "en": {
    "view_components": {
      "map": {
        "controls": {
          "attribution": {
            "feedback": "Map feedback",
            "toggle": "Toggle attribution"
          },
          "center": {
            "title": "Recenter Map"
          },
          "fullscreen": {
            "enter": "Enter fullscreen",
            "exit": "Exit fullscreen"
          },
          "geolocate": {
            "find_my_location": "Find my location",
            "location_not_available": "Location not available"
          },
          "logo": {
            "title": "Mapbox logo"
          },
          "navigation": {
            "reset_bearing": "Reset bearing to north",
            "zoom_in": "Zoom in",
            "zoom_out": "Zoom out"
          },
          "poi": {
            "hide": "Hide Points of Interest",
            "show": "Show Points of Interest",
            "title": "Points of Interest"
          }
        },
        "cta": "Click for Interactive Map",
        "pois": {
          "archaeology": "Archaeology",
          "atm": "ATM",
          "bank": "Bank",
          "beach": "Beach",
          "bus_station": "Bus Station",
          "car_electrician": "Car Electrician",
          "car_mechanic": "Car Mechanic",
          "car_wash": "Car Wash",
          "cardiologist": "Cardiologist",
          "cultural": "Cultural",
          "dentist": "Dentist",
          "electric_car_charger": "EV Charging Point",
          "ent": "Otolaryngologist (ENT)",
          "ferryport": "Ferry Port",
          "fire_station": "Fire Station",
          "gas_station": "Gas Station",
          "gastroenterologist": "Gastroenterologist",
          "general_doctor": "General Doctor",
          "gynaecologist": "Gynaecologist",
          "landmark": "Landmark",
          "marina": "Marina",
          "microbiologist": "Microbiologist",
          "motorcycle": "Motorcycle",
          "museum": "Museum",
          "nature": "Nature",
          "orthopedic": "Orthopedic",
          "parking": "Parking",
          "pediatrician": "Pediatrician",
          "pharmacy": "Pharmacy",
          "police_station": "Police Station",
          "private_medical_center": "Private Medical Center",
          "products": "Products",
          "public_hospital": "Public Hospital",
          "pulmonologist": "Pulmonologist",
          "shopping": "Shopping",
          "taxi_stop": "Taxi Stop",
          "veterinarian": "Veterinarian",
          "water_sports": "Water Sports"
        },
        "scroll_zoom_blocker": {
          "cmd_message": "Use ⌘ + scroll to zoom the map",
          "ctrl_message": "Use ctrl + scroll to zoom the map"
        },
        "title": "Map",
        "touch_pan_blocker": {
          "message": "Use two fingers to move the map"
        }
      }
    }
  }
}