/* global i18n */

/**
 * Assigns internationalization methods to a controller object and stores the given translations.
 * @function
 * @param {object} options - The options object.
 * @param {object} options.controller - The controller object to assign the methods to.
 * @param {object} options.translations - The translation data to be stored.
 * @param {string} options.scope - The translation scope.
 * @returns {undefined}
 */
const useI18n = ({ controller, translations, scope }) => {
  //! Custom Error Messages if required arguments are undefined
  if(!controller)   console.error("useI18n() requires a controller");
  if(!translations) console.error("useI18n() requires translations");
  if(!scope)        console.error("useI18n() requires a scope");

  if (translations && scope){
    i18n.store(translations);

    if (controller){
      Object.assign(controller, {
        t: (key, params = {}) => i18n.t(`${scope}.${key}`, params),
      });
    }
  }


};

export { useI18n };
export default useI18n;
