import ApplicationController from "../application_controller.js";
import lightGallery from "lightgallery";
import lgHash from "lightgallery/plugins/hash";
import lgVideo from "lightgallery/plugins/video";
import { useViewportSize } from "mixins";

export default class extends ApplicationController  {
  static values = {
    //* We paid for the licence and it gets passed in via rails credentials.
    licenseKey: { type: String, default: "0000-0000-000-0000" },
  };

  connect() {
    this.log_function("connect");

    useViewportSize(this);

    //* Save the original theme color to se it back After
    // this.originalViewport = document.querySelector("meta[name=\"viewport\"]").getAttribute("content");
    this.originalThemeColor = document.querySelector("meta[name=\"theme-color\"]").getAttribute("content");

    let viewport = this.viewport_label();

    this.lgInstance = lightGallery(this.element, {
      selector: ".gallery-item",
      plugins: [lgHash, lgVideo],
      thumbnail: false,
      download: false,
      licenseKey: this.licenseKeyValue,
      preload: 3,
      mode: "lg-slide",
      youTubePlayerParams: {
        modestbranding : 1,
        showinfo : 0,
        controls : 1,
      },
      getCaptionFromTitleOrAlt: false,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: false,
        speed: 100,
        preload: 3,
        mode: "lg-slide",
      },
      isMobile: function(){
        return viewport == "md" || viewport == "sm" || viewport == "xs" ? true : false;
      },
    });

    //* Set the theme color to the same color as the background so Iphone users won't get white bars when the gallery opens.
    this.element.addEventListener("lgBeforeOpen", () => {
      // document.querySelector("meta[name=\"viewport\"]").setAttribute("content", `${this.originalViewport}, viewport-fit=cover`);
      document.querySelector("meta[name=\"theme-color\"]").setAttribute("content", "#000000");
    });

    //* Set the theme color back to the original.
    this.element.addEventListener("lgBeforeClose", () => {
      // document.querySelector("meta[name=\"viewport\"]").setAttribute("content", this.originalViewport);
      document.querySelector("meta[name=\"theme-color\"]").setAttribute("content", this.originalThemeColor);
    });
  }

  open_slide(event){
    this.log_function("open_slide", event);

    let slide_index = parseInt(event.detail.slide_index);
    this.lgInstance.openGallery(slide_index);
  }

  disconnect(){
    this.log_function("disconnect");

    this.lgInstance.destroy();
  }
}
