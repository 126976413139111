import ApplicationController from "../application_controller.js";
import { jarallax, jarallaxVideo } from "jarallax";


// TODO handle youtube video backgrounds when user rejects third party cookies.
export default class extends ApplicationController  {
  static targets = [ "imageContainer" ];

  static values = {
    videoSrc: { type: String, default: "" },
  };

  connect() {
    this.log_function("connect");

    if(this.videoSrcValue) {
      this.log_function("connect", "videoSrc", this.videoSrcValue);
      jarallaxVideo();
    }

    this.init_attempts = 0;
    this.max_init_attempts = 5;

    this.interval = setInterval(() => {

      if(this.init_attempts > this.max_init_attempts){
        clearInterval(this.interval);
        return false;
      }

      if (this.image) {
        this.element.classList.add("jarallax");
        this.image.classList.add("jarallax-img");
        jarallax(this.element, this.config);
        clearInterval(this.interval);
      }

      this.init_attempts++;
    }, 1000);
  }

  handleVideoInsert(){
    this.log_function("handleVideoInsert");
    this.element.classList.add("loaded");
  }

  disconnect(){
    this.log_function("disconnect");

    if(this.interval) {
      clearInterval(this.interval);
    }

    jarallax(this.element, "destroy");
  }

  get image() {
    if (this.imageContainerTarget) {
      const imageElement = this.imageContainerTarget.querySelector("img");
      return imageElement || null;
    }
    return null;
  }

  get config(){
    this.log_function("get config");

    //* https://github.com/nk-o/jarallax/blob/master/README.md#options
    return {
      type: "scroll",
      speed: 0,
      videoSrc: this.videoSrcValue,
      onVideoInsert: this.handleVideoInsert.bind(this),
    };
  }
}
