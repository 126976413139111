import ApplicationController from "../application_controller.js";

export default class extends ApplicationController  {
  static classes = [ "reveal" ];
  static values = {
    max_height: { type: Number, default: 500 },
    enabled: { type: Boolean, default: true },
    sync: { type: String, default: "" },
  };

  connect() {
    this.log_function("connect");

    this.enabled = (this.element.clientHeight >= this.maxHeightValue) && this.enabledValue;

    if(this.enabled) {
      this.conceal();
    }



    if(this.syncValue) {
      this.sync_reveal = this.reveal.bind(this);
      this.sync_conceal = this.conceal.bind(this);

      this.reveal_event_name = `show-more:reveal:${this.syncValue}`;
      this.conceal_event_name = `show-more:conceal:${this.syncValue}`;
      document.addEventListener(this.reveal_event_name, this.sync_reveal);
      document.addEventListener(this.conceal_event_name, this.sync_conceal);
    }

  }

  reveal(event) {
    this.log_function("reveal", event);

    this.element.classList.add(this.revealClass);

    if(this.syncValue && event instanceof MouseEvent) {
      this.log_function("conceal", "Send Reveal Sync");
      const reveal_event = new Event(this.reveal_event_name);
      document.dispatchEvent(reveal_event);
    }
  }

  conceal(event) {
    this.log_function("conceal", event);

    this.element.classList.remove(this.revealClass);

    if(this.syncValue && event instanceof MouseEvent) {
      this.log_function("conceal", "Send Conceal Sync");
      const conceal_event = new Event(this.conceal_event_name);
      document.dispatchEvent(conceal_event);
    }
  }

  disconnect(){
    this.log_function("disconnect");

    if(this.enabled) {
      this.conceal();
    }

    if(this.syncValue) {
      document.removeEventListener(this.reveal_event_name, this.sync_reveal);
      document.removeEventListener(this.conceal_event_name, this.sync_conceal);
    }
  }
}
