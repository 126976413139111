import ApplicationController from "../application_controller.js";
import { useIntersection } from "stimulus-use";

export default class extends ApplicationController {
  static values = {
    selector: String,
  };

  connect() {
    this.log_function("connect");
    useIntersection(this);

    this.elements.forEach(element => {
      element.style.transition = `${element.style.transition} opacity 100ms`;
    });
  }

  appear(entry, observer) {
    this.log_function("appear", entry, observer);
    //* callback automatically triggered when the element
    //* intersects with the viewport (or root Element specified in the options)
    this.log_function("appear:entry", entry);
    this.log_function("appear:observer", observer);

    this.elements.forEach(element => {
      element.style.opacity = 0;
    });

  }

  disappear(entry, observer) {
    this.log_function("disappear", entry, observer);
    //* callback automatically triggered when the element
    //* leaves the viewport (or root Element specified in the options)
    this.log_function("disappear:entry", entry);
    this.log_function("disappear:observer", observer);

    this.elements.forEach(element => {
      element.style.opacity = null;
    });
  }

  disconnect(){
    this.log_function("disconnect");
  }

  get elements(){
    return document.querySelectorAll(this.selectorValue);
  }
}
